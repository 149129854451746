import React, { useEffect } from 'react';
// import api from '../lib/api2';

export default function Home({setTitle, setContextCommands}) {

	useEffect(() => {
	    setTitle('Home');
		const contextCommands=[];
		setContextCommands(contextCommands);
  }, [setTitle, setContextCommands]);

	return (
			<div>
      			<h1>Griffa - Procedura di gestione</h1>
			</div>
		);
}
