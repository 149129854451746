import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Marche({setTitle,setContextCommands}) {

	const columns=[
    { field: 'idMarca', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneMarca', headerName: 'Marca', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Marche');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idMarca"
      getUrl="/strumenti/marche?sort=descrizioneMarca"
      postUrl="/strumenti/marche"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
