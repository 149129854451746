import makeStyles from '@mui/styles/makeStyles';
export const drawerWidth = 240;

const useLayoutStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .action_d': {
      textDecoration: 'line-through',
      color: 'lightgrey'
    },
    '& .action_n': {
      color: 'green',
    },
    '& .action_e': {
      color: 'blue',
    },
    '& .noedit': {
      color: 'gray',
    },
    '& .MuiDataGrid-cell--editable':  {
      backgroundColor: 'rgba(214, 190, 156, 0.2)',
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
}));

export default useLayoutStyles;
