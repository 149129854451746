import React from 'react';
import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';
//import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {bool} from './../lib/dati';

const FormCheckbox=({name,defaultValue="",label,rules,xs=12,md=6,control,disabled,onChange,controlProps={}})=>(
  <Grid item xs={xs} md={md} style={{marginBottom:5}}>
    <Controller name={name} control={control} defaultValue={defaultValue} rules={rules} render={({ field, fieldState }) =>
        <FormControlLabel
          value={label}
          checked={bool(field.value)}
          control={<Checkbox {...field} disabled={disabled} onChange={e=>{field.onChange(e);if(onChange) onChange();}} {...controlProps}/>}
          label={label}
          labelPlacement="start"
        />
     } />
  </Grid>)

  export default FormCheckbox;


