import React, {useState,useRef,useEffect} from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import DrawerMenuItem from './DrawerMenuItem';
import {Logo} from './../../appearance/theme';
import Typography from '@mui/material/Typography';
import Icon from '../Icon';

export default function DrawerContent(props) {
  const scrollbarWidth=17;
  const [contentWidth,setContentWidth]=useState(0);
  const [containerWidth,setContainerWidth]=useState(0);
  const container=useRef(null);

  useEffect(()=>{
    setContainerWidth(container.current.clientWidth);
  },[container]);

  useEffect(()=>{
    setContentWidth(containerWidth-scrollbarWidth);
  },[scrollbarWidth,containerWidth]);

  const logoWidth=contentWidth-30;

  return (
    <div ref={container}>
      <img src={Logo} alt="Griffa Pianoforti" style={{width: logoWidth,padding:10,marginLeft:(contentWidth-logoWidth+scrollbarWidth)/2}} />
      <Divider />
      <List style={{width: contentWidth}}>
        {props.menu.map((m,i) => (
          <DrawerMenuItem {...m} key={i} />
        ))}
      </List>
      <Divider />
      <center style={{marginTop:20}}>
        <Typography variant="body2">
          <Icon icon="update" /> {process.env.REACT_APP_VERSION} - <Icon icon="account" /> { localStorage.getItem("username") }
        </Typography>
      </center>
    </div>
  );
}
