import React, { useState, useCallback } from 'react';
import beep from './../lib/beep';

export const NotificationContext = React.createContext({
  notification: [],
  pushNotification: () => {},
  popNotification: () => {}
});

export default function NotificationProvider({ children }) {
  const [notification, setNotification] = useState([]);

  const contextValue = {
    notification,
    pushNotification: useCallback((message, status="success") => {
        beep(status);
        setNotification(prevNotification => [...notification, { message, status }]);
    }, [notification]),
    popNotification: useCallback(() => {
      var nextNotification=[...notification]
      nextNotification.pop();
      setNotification(nextNotification);
    }, [notification])
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
