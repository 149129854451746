import React from 'react';
import { InputBase  } from "@mui/material";

const cellMultiline=(options,valueField='value',labelField='label')=>{
  return{
    renderCell:params=>{
      const { value }=params;
      return (
        <InputBase 
          value={value}
          multiline
          fullWidth
          contentEditable={false}
          style={{padding: 0}}
          inputProps={{
            style:{fontSize:'0.875rem'}
          }}
          />
        
    )},

    renderEditCell:params=>{
      const { id, value, api, field }=params;
      return (
        <InputBase 
          value={value}
          multiline
          fullWidth
          style={{padding: 0}}
          inputProps={{
            style:{fontSize:'0.875rem'}
          }}
          onChange={e=>api.setEditCellValue({ id, field, value: e.target.value }, e)}
          />
      );
    }
  }
}

export default cellMultiline;