import React from 'react';
import useNotification from './useNotification';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function Notification() {
  const { notification, popNotification } = useNotification();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    popNotification();
  };

  if (!notification || notification.length===0) return "";

  const currNotification=notification[notification.length-1];

  if (notification && notification.length>0)  return (
    <Snackbar open={!!notification} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={2000/*currNotification.status==='success'?2000:null*/} style={{marginTop: 60}} onClose={handleClose}>
      <Alert variant="filled" severity={currNotification.status} onClose={handleClose}>
        {currNotification.message}
      </Alert>
    </Snackbar>
  )
}
