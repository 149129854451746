import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SimpleDataGrid from 'components/SimpleDataGrid';
import EditIcon from '@mui/icons-material/Edit';

export default function ContrattiModelli({setTitle,setContextCommands}) {
  const navigate = useNavigate();

	const columns=[
    { field: 'idModelloContratto', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizione', headerName: 'Descrizione', editable:true, flex:true },
  ]

  const rowActions = [
    {
      label: 'Modifica', onClick: id => navigate("/configurazione/contratti/modelli/" + id + "/dettaglio"), disabled: params => params.row._action==='n',
      icon: <EditIcon />
    },
  ];

	useEffect(() => {
	    setTitle('Configurazione - Modelli contratti');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idModelloContratto"
      getUrl="/contratti/modelli?sort=descrizione"
      postUrl="/contratti/modelli"
      columns={columns}
      setContextCommands={setContextCommands}
      rowActions={rowActions}
    />
}