import React, {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import DialogClienti from './../components/DialogClienti';
import api from './../lib/api2';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormClienteWrapper({name, label, control, onSelect, xs=12, md=6, required=false}) {
    return (
        <Grid item xs={xs} md={md}>
            <Controller rules={{required:required}} name={name} control={control} render={({ field: { onChange, value }, fieldState }) =>
                <FormCliente onChange={cliente=>{
                    onChange(cliente);
                    if (onSelect) onSelect(cliente);
                }} value={value} label={label} required={required} fieldState={fieldState} />
            } />
        </Grid>
    )
}

function FormCliente({value, onChange, label, required=false, fieldState}) {
    const [isDialogClientiOpen, setDialogClientiOpen] = useState(false);
    const [descrizione, setDescrizione] = useState('');

    const handleClienteChange = cliente => {
		setDialogClientiOpen(false);
        if (cliente)
            onChange(cliente);
	}

    const handleUndo = () => {
        onChange("");
    };
    
    useEffect(()=>{
        setDescrizione('');
        if (value) {
            api.get('/clienti?idCliente='+value).then(data=>{
                setDescrizione(data[0].cognome + ' ' +data[0].nome)
            });
        }
    },[value])

    
//disabled={true}
    return (
        <React.Fragment>
            {isDialogClientiOpen && <DialogClienti onSelect={handleClienteChange} open={true} /> }
            
            <TextField disabled={true}  value={descrizione} label={label} fullWidth required={required} error={!!fieldState.error} InputProps={{endAdornment: (
                <Stack direction="row">
                    <IconButton sx={{ color: "secondary.main" }} onClick={()=>setDialogClientiOpen(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton sx={{ color: "info.main" }} onClick={()=>window.open("/clienti/"+value)} disabled={!value}>
                        <InfoIcon />
                    </IconButton>
                    <IconButton sx={{ color: "black.main" }} onClick={handleUndo} disabled={!value}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
                ),
            }}/>
        </React.Fragment> 
    )
}