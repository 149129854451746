import React, {useState,useEffect} from 'react';
import Grid from '@mui/material/Grid';
import DialogStrumenti from './../components/DialogStrumenti';
import api from './../lib/api2';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormStrumentoWrapper({name, label, onSelect, control, xs=12, md=6, includiRiparazioni=false, contrattoTipo}) {
    return (
        <Grid item xs={xs} md={md}>
            <Controller name={name} control={control} render={({ field: { onChange, value } }) =>
                <FormStrumento onChange={strumento=>{
                    onChange(strumento);
                    if (onSelect) onSelect(strumento);
                }} value={value} label={label} includiRiparazioni={includiRiparazioni} contrattoTipo={contrattoTipo}/>
            } />
        </Grid>
    )
}

function FormStrumento({value, onChange, label, includiRiparazioni, contrattoTipo}) {
    const [isDialogStrumentiOpen, setDialogStrumentiOpen] = useState(false);
    const [descrizione, setDescrizione] = useState('');

    const handleStrumentoChange = strumento => {
		setDialogStrumentiOpen(false);
        if (strumento)
            onChange(strumento);
	}

    const handleUndo = () => {
        onChange("");
    };
    
    useEffect(()=>{
        setDescrizione('');
        if (value) {
            api.get('/strumenti?matricola='+value).then(data=>{
                setDescrizione(data[0].descrizioneMarca+' - '+data[0].descrizioneTipoStrumento+' '+data[0].descrizioneStruttura+' '+data[0].modello+' - '+data[0].matricola)
            });
        }
    }, [contrattoTipo, includiRiparazioni, value])

    return (
        <React.Fragment>
            {isDialogStrumentiOpen && <DialogStrumenti onSelect={handleStrumentoChange} open={true} includiRiparazioni={includiRiparazioni} contrattoTipo={contrattoTipo}/> }
            
            <TextField disabled={true} value={descrizione} label={label} fullWidth InputProps={{endAdornment: (
                <Stack direction="row">
                    <IconButton sx={{ color: "secondary.main" }} onClick={()=>setDialogStrumentiOpen(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton sx={{ color: "info.main" }} onClick={()=>window.open("/strumenti/"+value)} disabled={!value}>
                        <InfoIcon />
                    </IconButton>
                    <IconButton sx={{ color: "black.main" }} onClick={handleUndo} disabled={!value}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
                ),
            }}/>
        </React.Fragment> 
    )
}