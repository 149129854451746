import { rimuoviNull } from './dati';

export const apiBaseURL = "https://gestione.griffaefigli.it/api";


function authStr()  {
  /*if (false) {
    //return "bGltaXRhdG9AZGVtbzp1dGVudGVsaW1pdGF0bw=="; //utente limitato
    return "YWRtaW5AZGVtbzpub3ZlbWJyZTIwMTQ="; //admin
  }*/
  return getCookie("authstr");
}

export function getCookie(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export function apiGet(url, callBack, formato) {
    formato = formato || "json";
    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + authStr());
    return fetch(apiBaseURL + url, {
        method: 'GET',
        headers: headers,
    }).then(res => {
        if (res.ok) {
            switch (formato) {
                case "json":
                    return res.json();
                case "blob":
                    return res.blob();
                case "text":
                case "xml":
                  return res.text();
                default:
            }
            var contentType = res.headers.get("content-type");
            if (!(contentType.indexOf("text/html") >= 0 || contentType.indexOf("json") >= 0)) {
                return res.blob();
            } else {
                return res.json();
            }
        } else {
            switch (res.status) {
                case 401:
                    // console.log("Non autorizzato",url);
                    return "";
                    // return ({ errore: res.status });
                default:
                    return ({ errore: res.status });
            }
        }
    }).then(data => {
        if (callBack) {
            if (formato==='json') {
              data=rimuoviNull(data);
            }
            callBack(data);
        } else {
            window.open(window.URL.createObjectURL(data));
        }
    });
}

export function apiPost(url, body, callBack) {
    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + authStr());

    return fetch(apiBaseURL + url, {
        method: 'POST',
        headers: headers,
        body: body,
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else {
            switch (res.status) {
                case 401:
                    // console.log("Non autorizzato");
                    return ({ errore: res.status });
                default:
                    return ({ errore: res.status });
            }
        }
    }).then(data => {
        callBack(data);
    });
}

export function apiPut(url, body, callBack) {
    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + authStr());

    return fetch(apiBaseURL + url, {
        method: 'PUT',
        headers: headers,
        body: body,
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else {
            switch (res.status) {
                case 401:
                    // console.log("Non autorizzato");
                    return ({ errore: res.status });
                default:
                    return ({ errore: res.status });
            }
        }
    }).then(data => {
        callBack(data);
    });
}

export function apiDelete(url, callBack) {
    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + authStr());

    return fetch(apiBaseURL + url, {
        method: 'DELETE',
        headers: headers,
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else {
            switch (res.status) {
                case 401:
                    // console.log("Non autorizzato");
                    return ({ errore: res.status });
                default:
                    return ({ errore: res.status });
            }
        }
    }).then(data => {
        callBack(data);
    });
}
