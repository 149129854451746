import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
// import { useForm  } from 'react-hook-form';

export default function AggiungiDestinatari({onChange, open}) {
    // const { control, handleSubmit } = useForm({mode: 'all'});
	const apiRef = useGridApiRef();
    const [marche, setMarche] = useState([]);
	const [strutture, setStrutture] = useState([]);
	const [tipiContratti, setTipiContratti] = useState([]);

	const [filtroMarche, setFiltroMarche] = useState([]);
	const [filtroStrutture, setFiltroStrutture] = useState([]);
	const [filtroTipoContratto,setFiltroTipoContratto] = useState([]);
	const [filtroModello, setFiltroModello] = useState('');
	const [filtroPrezzoMin, setFiltroPrezzoMin] = useState('');
	const [filtroPrezzoMax, setFiltroPrezzoMax] = useState('');

	const [contatti, setContatti] = useState([]);

	const columnsFiltroDestinatari = [
		{ field: 'recapito', headerName: 'E-mail',  flex:1 },
		{ field: 'denominazioneCliente', headerName: 'Cliente',  width: 500},
	]

	const resetFiltro = () => {
		setFiltroMarche([]);
		setFiltroModello('');
		setFiltroPrezzoMin('');
		setFiltroPrezzoMax('');
		setContatti([]);
	};

    useEffect(() => {
		api.get('/strumenti/marche').then(data=>{
			setMarche(data);
		});
		api.get('/strumenti/strutture').then(data=>{
			setStrutture(data);
		});
		api.get('/contratti/tipi').then(data=>{
			setTipiContratti(data);
		});
  	}, []);

	const handleTrova = () => {
		const r=[];
		if (filtroMarche.length > 0) {
			const idMarcheConcatenati = filtroMarche.map(marca => marca.idMarca).join(',');
			r.push(`marca=${idMarcheConcatenati}`);
		}
		if (filtroModello) {
			r.push(`modello=%25${filtroModello}%25|like`);
		}
		if (filtroPrezzoMin || filtroPrezzoMax) {
			r.push("valore="+filtroPrezzoMin+","+filtroPrezzoMax+"|between");
		}
		if (filtroStrutture.length > 0) {
			const idStruttureConcatenati = filtroStrutture.map(struttura => struttura.idStruttura).join(',');
			r.push(`struttura=${idStruttureConcatenati}`);
		}
		if (filtroTipoContratto.length > 0) {
			const idTipiContrattiConcatenati = filtroTipoContratto.map(tipoContratto => `'${tipoContratto.codice}'`).join(',');
			r.push(`tipoContratto=${idTipiContrattiConcatenati}`);
		}
		const url='/mailing/clienti?'+r.join("&");
		api.get(url).then(data=>{
			setContatti(data);
		});
	}

	const aggiungiDestinatari = () => {
		const contattiSelezionati = apiRef.current.getSelectedRows();
		onChange(contattiSelezionati);
		resetFiltro();
	}

    return (
        <Dialog open={open} scroll="paper" onClose={() => { onChange(null); resetFiltro(); }} fullWidth={true} maxWidth = {'xl'}>
			<DialogTitle>
				Selezione destinatari
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} style={{marginTop:'10px'}}>
					<Grid item xs={12} md={11/6}>
						<Autocomplete
						multiple
						options={marche}
						getOptionLabel={(option) => option.descrizioneMarca}
						defaultValue={[]}
						limitTags={2}
						renderInput={(params) => (
						<TextField
							{...params}
							label="Marca"
						/>
						)}
						isOptionEqualToValue={(option,value)=>option.idMarca===value.idMarca}
						onChange={(event, newValue) => {setFiltroMarche(newValue)}}
						/>
					</Grid>
					<Grid item xs={12} md={11/6}>
						<TextField label="Modello" value={filtroModello} onChange={e=>setFiltroModello(e.target.value.toLowerCase())} fullWidth style={{marginBottom:'20px'}}/>
					</Grid>
					<Grid item xs={12} md={11/6}>
						<TextField label="Prezzo (min) " value={filtroPrezzoMin} onChange={e=>setFiltroPrezzoMin(e.target.value.toLowerCase())} fullWidth style={{marginBottom:'20px'}}/>
					</Grid>
					<Grid item xs={12} md={11/6}>
						<TextField label="Prezzo (max) " value={filtroPrezzoMax} onChange={e=>setFiltroPrezzoMax(e.target.value.toLowerCase())} fullWidth style={{marginBottom:'20px'}}/>
					</Grid>
					<Grid item xs={12} md={11/6}>
						<Autocomplete
							multiple
							options={strutture}
							getOptionLabel={(option) => option.descrizioneStruttura}
							defaultValue={[]}
							limitTags={2}
							renderInput={(params) => (
							<TextField
								{...params}
								label="Struttura"
							/>
							)}
							isOptionEqualToValue={(option,value)=>option.idStruttura===value.idStruttura}
							onChange={(event, newValue) => setFiltroStrutture(newValue)}
						/>
					</Grid>
					<Grid item xs={12} md={11/6}>
						<Autocomplete
							multiple
							options={tipiContratti}
							getOptionLabel={(option) => option.descrizione}
							defaultValue={[]}
							limitTags={2}
							renderInput={(params) => (
							<TextField
								{...params}
								label="Tipo contratto"
							/>
							)}
							isOptionEqualToValue={(option,value)=>option.codice===value.codice}
							onChange={(event, newValue) => setFiltroTipoContratto(newValue)}
						/>
					</Grid>
					<Grid item xs={12} md={1}>
						<Button onClick={handleTrova} style={{height:56}} size="large" variant="outlined" fullWidth>Trova</Button>         
					</Grid>
				</Grid>
				<Grid item xs={12} md={12} style={{ height: '60vh' }}>
					<DataGridPro
						apiRef={apiRef}
						rows={contatti}
						columns={columnsFiltroDestinatari}
						getRowId={r => r.idRecapitoCliente}
						checkboxSelection
						disableRowSelectionOnClick
						rowHeight={38}
						sx={{
							cursor:'pointer',
							".row-green": {
								background: "lightgreen",
								color: "white !important"
							}
						}}
					/>
				</Grid>
				
				<Grid container spacing={1} style={{marginTop:'10px'}}>
					<Grid item xs={12} md={4}>
						Totale righe: { contatti.length }
					</Grid>
					<Grid item xs={12} md={4}>
						<Button variant="contained" fullWidth onClick={ () => apiRef.current.exportDataAsCsv() }>Esporta .CSV</Button>         
					</Grid>
					<Grid item xs={12} md={4}>
						<Button variant="contained" fullWidth onClick={ () => aggiungiDestinatari() }>Aggiungi destinatari selezionati</Button>         
					</Grid>
				</Grid>
				
			</DialogContent>
    	</Dialog>
    )
}