import React, {useEffect,useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import api from '../lib/api2';
import { Button } from '@mui/material';

export default function DialogCampi({ onClose }) {
    const [campiPDF, setCampiPDF] = useState([]);

    useEffect(() => {
        api.get("/report/contratti/campi").then(data => {
            setCampiPDF(data);
        });
    }, []);

    return (
        <Dialog open={true} onClose={onClose} maxWidth='xl'>
            <DialogTitle>Elenco dei campi disponibili con placeholder</DialogTitle>
            <DialogContent>
                <div style={{ marginBottom: '1rem' }}>
                    Per la formattazione aggiungere dopo il nome del campo, il carattere "|"<br />
                    Seguito da:
                    <ul>
                        <li>date: se si vuole formattarlo in data GG/MM/AAAA;</li>
                        <li>number: se si vuole formattarlo numero con due decimali;</li>
                        <li>numeroInLettere: se si vuole formattarlo in numero con due decimali ed aggiungere anche la conversione in lettere;</li>
                    </ul>
                </div>
                <div style={{ columnCount: 4 }}>
                    {campiPDF.map(field => (
                        <div key={field}>{`{${field}}`}</div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Chiudi</Button>
            </DialogActions>
        </Dialog>
    );
}