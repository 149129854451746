import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const FormTitle=({label,variant='h5',children,style={}})=>(
  <Grid item xs={12} style={{marginBottom:5}}>
    <Typography variant={variant} style={style}>
      {label} {children}
    </Typography>
  </Grid>)

  export default FormTitle;
