import React, { useState, useCallback } from 'react';
import { apiGet } from 'lib/api';

export const ReportContext = React.createContext({
  loader: false,
  openReport: () => {}
});

export default function ReportProvider({ children }) {
  const [loader, setLoader] = useState(false);

  const contextValue = {
    loader,
    openReport: useCallback( url => {
      apiGet(url, data=>{
        window.open(window.URL.createObjectURL(data));
        setLoader(false);
      },"blob")
      setLoader(true);
    }, [])
  };

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
}
