import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import api from '../lib/api2';


export default function DialogModelliContratti({onClose}){
    const [modelli, setModelli] = useState([]);

    useEffect(()=>{
        api.get('/contratti/modelli').then(data => {
            setModelli(data);
        });
    }, []);

    return (
        <Dialog onClose={()=>onClose("")} open={true}>
            <DialogTitle>Modelli di contratto</DialogTitle>
            <List sx={{ pt: 0 }}>
                {modelli.map(modello => (
                    <ListItem disableGutters key={modello.idModelloContratto}>
                        <ListItemButton onClick={() => onClose(modello)}>
                            <ListItemText primary={modello.descrizione} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}