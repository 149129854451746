import React, { useState, useEffect } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import api from '../lib/api2';
import { FixedSizeList } from 'react-window';
import CircularProgress from '@mui/material/CircularProgress';

export default function DialogBanche({onSelect,open}) {
  const [banche, setBanche] = useState(null);
  const [isProgressVisible, setProgressVisible] = useState(false);

  const caricaDati=()=>{
    setProgressVisible(true);
    api.get("/banche").then(data=>{
      setBanche(data);
      setProgressVisible(false);
    })
  }

  useEffect(()=>{
    caricaDati();
  },[])

  const renderRow=({ index, style })=>{
    const banca=banche[index];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={banca.descrizioneBanca} style={{whiteSpace: 'nowrap'}} title={banca.descrizioneBanca} onClick={()=>onSelect(banca.descrizioneBanca)}/>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Dialog open={open || true} scroll="paper" onClose={()=>onSelect(null)} fullWidth>
      <DialogTitle>
        Selezione banca
      </DialogTitle>
      <DialogContent>
        <Grid container style={{marginTop:5}}>
          <Grid item xs={12}>
            {isProgressVisible &&
              <center><CircularProgress /></center>
            }
            {banche!==null && banche.length>0 &&
              <FixedSizeList
                height={400}
                width={'100%'}
                itemCount={banche.length}
                itemSize={46}
                overscanCount={5}
                style={{overflowX: 'hidden'}}
              >
                {renderRow}
              </FixedSizeList>
            }
            {banche!==null && banche.length===0 &&
              <center style={{margin:30}}><Typography sx={{ color: "warning.main" }}>Nessun risultato</Typography></center>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
