const menu = [
  { id: 0, text: "Home", icon: "home", url: "/", home: true },
  { id: 10, text: "-" },
  { id: 1, text: "Clienti", icon: "account-group", url: "/clienti", home: false },
  { id: 2, text: "Strumenti", icon: "piano", url: "/strumenti", home: false },
  { id: 20, text: "-" },
  { id: 3, text: "Noleggi", icon: "cash-sync", url: "/contratti/n", home: false },
  { id: 4, text: "Vendite", icon: "cash-register", url: "/contratti/v", home: false },
  // { id: 5, text: "Comodato d'uso", icon: "cash-register", url: "/contratti/u", home: false },
  { id: 6, text: "Riparazioni", icon: "wrench", url: "/contratti/r", home: false },
  { id: 7, text: "Noleggi per concerti", icon: "music", url: "/contratti/c", home: false },
  { id: 50, text: "-" },
  { id: 8, text: "Documenti", icon: "file-certificate", url: "/documenti", home: false },
  { id: 9, text: "Fatture previste", icon: "piggy-bank", url: "/fatture/previste", home: false },
  { id: 8, text: "Pagamenti", icon: "cash-multiple", url: "/pagamenti", home: false },
  { id: 10, text: "Mailing", icon: "mail", url: "/mailing", home: false },
  { id: 80, text: "-" },
  // { id: 11, text: "Statistiche", icon: "chart-bar", url: "/statistiche", home: false },
  { id: 12, text: "Configurazione",icon: "cogs", subMenu: [
    { id: 101, text: "Banche", icon: "bank", url: "/configurazione/banche" },
    { id: 102, text: "Vettori", icon: "car-lifted-pickup", url: "/configurazione/vettori" },
    { id: 103, text: "Clienti - Tipi", icon: "account-group", url: "/configurazione/clienti/tipi" },
    { id: 104, text: "Contratti - Tipi", icon: "file", url: "/configurazione/contratti/tipi" },
    { id: 105, text: "Contratti - Modelli", icon: "file", url: "/configurazione/contratti/modelli" },
    { id: 106, text: "IVA", icon: "sack-percent", url: "/configurazione/iva" },
    { id: 107, text: "Strumenti - Colori", icon: "palette", url: "/configurazione/strumenti/colori" },
    { id: 108, text: "Strumenti - Marche", icon: "label", url: "/configurazione/strumenti/marche" },
    { id: 109, text: "Strumenti - Strumenti", icon: "shape", url: "/configurazione/strumenti/strutture" },
    { id: 110, text: "Strumenti - Tipi", icon: "piano", url: "/configurazione/strumenti/tipi" },
    { id: 111, text: "Strumenti - Stati", icon: "alert", url: "/configurazione/strumenti/stati" },
    { id: 112, text: "Contratti - Scadenze", icon: "alert", url: "/configurazione/contratti/scadenze" },
    { id: 113, text: "Parametri", icon: "tune", url: "/configurazione" },
  ]},
  { id: 90, text: "-" },
  { id: 12, text: "Disconnetti", icon: "logout", url: "/logout", home: false },
];

export default menu;
