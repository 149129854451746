import React, { useState, useEffect, useCallback } from 'react';
import api from 'lib/api2';
import Filtro from './Filtro';
//import { useNavigate } from "react-router-dom";
import ContextCommand from 'components/ContextCommand';
import { DataGridPro, useGridApiRef  } from '@mui/x-data-grid-pro';
import { unstable_debounce as debounce } from '@mui/utils';

export default function ClientiElenco({setTitle, setContextCommands}) {
	//const navigate = useNavigate();
	const apiRef = useGridApiRef();
	const [rowCount, setRowCount] = useState(0);
	//const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [filtroDescrizione, setFiltroDescrizione] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTitle('Clienti');
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="2" icon="plus" onClick={ ()=>window.open("/clienti/new") }>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands ]);

	const columns=[
		{ field: 'idCliente', headerName: 'ID', width: 80, sortable: false },
    	{ field: 'denominazione', headerName: 'Demonimazione cliente', flex: 1, sortable: false },
  	]

	const getRowCount=useCallback(async ()=>{
		const url = "/clienti/count" + (filtroDescrizione ? '?ricerca='+filtroDescrizione : '');
		const c = await api.get(url);
		setRowCount(parseInt(c, 10 ));
	}, [filtroDescrizione])

	const handleFetchRows = React.useCallback(async ({firstRowToRender, lastRowToRender}) => {
		const limit = lastRowToRender-firstRowToRender;
		var url="/clienti?sort=cognome,nome&limit="+limit+"&offset="+firstRowToRender;
		if (filtroDescrizione) {
			url+="&ricerca=" + filtroDescrizione;
		}
		api.get(url).then(righe=>{
			apiRef.current.unstable_replaceRows(firstRowToRender, righe);
		});
	}, [apiRef, filtroDescrizione]);

	const debouncedHandleFetchRows = React.useMemo(
		() => debounce(handleFetchRows, 200),
		[handleFetchRows],
	);

	useEffect(() => {
		setLoading(false);
		getRowCount();
		handleFetchRows({firstRowToRender:0, lastRowToRender:100});
  	}, [handleFetchRows, getRowCount]);


	const filtra=async filtro=>{
		//apiRef.current.setRows([]);
		await getRowCount();
		setFiltroDescrizione(filtro);
	}
	
	return (
		<div style={{marginBottom:50, height:'85vh'}}>
			<Filtro filtra={filtra} />
			<DataGridPro
				loading={loading}
				columns={columns}
				rows={[]}
				apiRef={apiRef}
				hideFooterPagination
				sortingMode="server"
				filterMode="server"
				rowsLoadingMode="server"
				rowCount={rowCount}
				onFetchRows={debouncedHandleFetchRows}
				experimentalFeatures={{
					lazyLoading: true
				}}
				style={{height:'100%'}}
				getRowId={r => r.idCliente}
				getCellClassName={r=>"noedit"}
				showCellRightBorder={false}
        		showColumnRightBorder={true}
				paginationMode="server"
				keepNonExistentRowsSelected
				onRowClick={r => window.open("/clienti/" + r.id)}
				sx={{
					cursor:'pointer',
					".row-green": {
						background: "lightgreen",
						color: "white !important"
					  }
				}}
				throttleRowsMs={1000}
			/>
		</div>
	);
}
//

/*
				onSelectionModelChange={(newRowSelectionModel) => {
					setRowSelectionModel(newRowSelectionModel);
				}}
				rowSelectionModel={rowSelectionModel}
				*/