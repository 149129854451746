import React from 'react';
import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

export default function FormDateRangePickerSingle({name,defaultValue=[null,null],label,rules,xs=12,md=6,type,control,required,disabled,onChange,rows,multiline,controlProps={}}) {

  var rules2={};
  if (required) rules2.required=true;
  
  rules2={...rules2,...rules};

  return (
    <Grid item xs={xs} md={md} style={{marginBottom:5}}>
      <Controller
        control={control}
        name={name}
        rules={rules2}
        defaultValue={defaultValue}
        render={({ field,fieldState }) => {
            return (
            <SingleInputDateRangeField
                {...field}
                label={label} 
                fullWidth
                clearable
                inputRef={field.ref}
                onChange={date => {
                    field.onChange(date);
                    if(onChange) onChange(date);
                }}
                error={!!fieldState.error}
                required={required} 
                disabled={disabled}
                {...controlProps}
            />
            );
        }}
      />
    </Grid>)
  }


