import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';

export default function NotFound(props) {
  useEffect(()=>props.setTitle("Errore - Pagina non trovata"),[props])
  return (<div>
      <Typography variant="h1">
        Pagina non trovata
      </Typography>
      <Typography paragraph>
        L'indirizzo digitato non è valido
      </Typography>
    </div>);

}
