import React, { useEffect } from 'react';
// import api from '../lib/api2';

export default function Statistiche({setTitle,setContextCommands}) {

	useEffect(() => {
	    setTitle('Statistiche');
  }, [setTitle]);

	return (
			<div>

			</div>
		);
}
