import React, { useEffect, useRef } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Vettori({setTitle,setContextCommands}) {
	const dettagli=useRef();

	const columns=[
		{ field: 'idVettore', headerName: 'ID', width: 90, type:'number' },
    { field: 'denominazioneVettore', headerName: 'Vettore', editable:true, flex:true },
		{ field: 'email', headerName: 'email', width: 360, editable:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Vettori');
  }, [setTitle]);

	return <SimpleDataGrid
			ref={dettagli}
      idField="idVettore"
      getUrl="/vettori"
      postUrl="/vettori"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
