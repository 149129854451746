import React from 'react';
import { Routes, Route } from "react-router-dom";
import NotFound from './errors/NotFound'
import Login from './Login';
import Logout from './Logout';
import Home from './Home';
import ClientiElenco from './ClientiElenco';
import ClienteDettaglio from './Clienti';
import StrumentiElenco from './StrumentiElenco';
import StrumentiDettaglio from './Strumenti';
import FatturePreviste from './FatturePreviste';
import MailingElenco from './MailingElenco';
import Mailing from './Mailing';
import Pagamenti from './Pagamenti';
import DocumentiElenco from './DocumentiElenco';
import DocumentiDettaglio from './Documenti';
import ContrattiElenco from './ContrattiElenco';
import ContrattiDettaglio from './Contratti';
import Statistiche from './Statistiche';
import StrumentiColori from './configurazione/StrumentiColori';
import StrumentiStrutture from './configurazione/StrumentiStrutture';
import StrumentiMarche from './configurazione/StrumentiMarche';
import StrumentiTipi from './configurazione/StrumentiTipi';
import StrumentiStati from './configurazione/StrumentiStati';
import AggiornamentoScadenze from 'configurazione/AggiornamentoScadenze';
import ContrattiTipi from './configurazione/ContrattiTipi';
import ClientiTipi from './configurazione/ClientiTipi';
import ContrattiModelli from './configurazione/ContrattiModelli';
import ContrattiModelliDettagli from './configurazione/ContrattiModelliDettagli';
import Parametri from './configurazione/Parametri';
import IVA from './configurazione/IVA';
import Banche from './configurazione/Banche';
import Vettori from './configurazione/Vettori';
export default function MyRoutes({setTitle,setContextCommands})  {
  const defaultProps={setTitle,setContextCommands};
  return (
    <Routes>
      <Route path="/" element={ <Home {...defaultProps} /> } />
      <Route path="/login" element={ <Login {...defaultProps} /> } />
      <Route path="/logout" element={ <Logout {...defaultProps} /> } />
      <Route path="/clienti" element={ <ClientiElenco  {...defaultProps} /> } />
      <Route path="/clienti/:id" element={ <ClienteDettaglio {...defaultProps} /> } />
      <Route path="/strumenti" element={ <StrumentiElenco  {...defaultProps} /> } />
      <Route path="/strumenti/:id" element={ <StrumentiDettaglio {...defaultProps} /> } />
      <Route path="/fatture/previste" element={ <FatturePreviste  {...defaultProps} /> } />
      <Route path="/mailing" element={ <MailingElenco  {...defaultProps} /> } />
      <Route path="/mailing/:id" element={ <Mailing {...defaultProps} /> } />
      <Route path="/documenti" element={ <DocumentiElenco  {...defaultProps} /> } />
      <Route path="/documenti/:id" element={ <DocumentiDettaglio {...defaultProps} /> } />
      <Route path="/pagamenti" element={ <Pagamenti  {...defaultProps} /> } />
      <Route path="/contratti/:tipo" element={ <ContrattiElenco {...defaultProps} /> } />
      <Route path="/contratti/:tipo/:id?" element={ <ContrattiDettaglio {...defaultProps} /> } />
      <Route path="/statistiche" element={ <Statistiche  {...defaultProps} /> } />
      <Route path="/configurazione/strumenti/colori" element={ <StrumentiColori path="/" {...defaultProps} /> } />
      <Route path="/configurazione/strumenti/strutture" element={ <StrumentiStrutture path="/" {...defaultProps} /> } />
      <Route path="/configurazione/strumenti/marche" element={ <StrumentiMarche  {...defaultProps} /> } />
      <Route path="/configurazione/strumenti/tipi" element={ <StrumentiTipi  {...defaultProps} /> } />
      <Route path="/configurazione/strumenti/stati" element={ <StrumentiStati  {...defaultProps} /> } />
      <Route path="/configurazione/contratti/scadenze" element={ <AggiornamentoScadenze  {...defaultProps} /> } />
      <Route path="/configurazione/contratti/tipi" element={ <ContrattiTipi  {...defaultProps} /> } />
      <Route path="/configurazione/contratti/modelli" element={ <ContrattiModelli  {...defaultProps} /> } />
      <Route path="/configurazione/contratti/modelli/:id/dettaglio" element={ <ContrattiModelliDettagli  {...defaultProps} /> } />
      <Route path="/configurazione/clienti/tipi" element={ <ClientiTipi path="/configurazione/clienti/" {...defaultProps} /> } />
      <Route path="/configurazione/vettori" element={ <Vettori {...defaultProps} /> } />
      <Route path="/configurazione" element={ <Parametri  {...defaultProps} /> } />
      <Route path="/configurazione/iva" element={ <IVA  {...defaultProps} /> } />
      <Route path="/configurazione/banche" element={ <Banche  {...defaultProps} /> } />
      <Route path="*" element={ <NotFound {...defaultProps} /> } />
    </Routes>
  )
}
