import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Parametri({setTitle,setContextCommands}) {

	const columns=[
    { field: 'chiave', headerName: 'Chiave', width: 300, type:'number' },
    { field: 'valore', headerName: 'Valore', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Parametri');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="chiave"
      getUrl="/configurazione?sort=chiave"
      postUrl="/configurazione"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
