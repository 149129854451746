import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const cellSelect=(options,valueField='value',labelField='label')=>{
  return{
    valueFormatter:params=>{
      if ( params.value==="" || params.value==null || options.length===0) return "";
      const option=options.find(s=>s[valueField].toString()===params.value.toString());
      if (!option) return "("+params.value+")";
      return option[labelField]
    },

    renderEditCell:params=>{
      const { id, value, api, field }=params;
      return (
        <FormControl fullWidth>
          <Select value={value==null?'':value} onChange={e=>api.setEditCellValue({ id, field, value: e.target.value }, e)}>
            <MenuItem value="" key="">&nbsp;</MenuItem>
            {options.map(o=><MenuItem value={o[valueField]} key={o[valueField]}>{o[labelField]}</MenuItem>)}
          </Select>
        </FormControl>
      );
    }
  }
}

export default cellSelect;
