import { createTheme } from '@mui/material/styles';
import { itIT } from '@mui/x-data-grid';

const theme=createTheme({
  components: {
    MuiDataGrid: {
      defaultProps: {
        //autoHeight:true,
        hideFooter:true,
        disableColumnMenu:true,
        localeText: itIT.components.MuiDataGrid.defaultProps.localeText
      }
    }
  },
  palette: {
    primary: {
      main: '#D6BE9C'
    },
    secondary: {
      main: '#893F45'
    },
  },
  overrides: {
    MuiIconButton: {
      color: '#FF0000'
    }
  }
});

export const Logo="/griffa.png"; //logo;

export default theme;
