import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function Clienti({setTitle,setContextCommands}) {

	const columns=[
    { field: 'idTipoCliente', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneTipoCliente', headerName: 'Tipologia', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Clienti');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idTipoCliente"
      getUrl="/clienti/tipi"
      postUrl="/clienti/tipi"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
