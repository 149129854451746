import React, { useEffect } from 'react';
import SimpleDataGrid from 'components/SimpleDataGrid';

export default function StrumentiTipi({setTitle,setContextCommands}) {

	const columns=[
    { field: 'idTipoStrumento', headerName: 'ID', width: 90, type:'number' },
    { field: 'descrizioneTipoStrumento', headerName: 'Tipo strumento', editable:true, flex:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Strumenti');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="idTipoStrumento"
      getUrl="/strumenti/tipi?sort=descrizioneTipoStrumento"
      postUrl="/strumenti/tipi"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
