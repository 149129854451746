import React, { useState, useEffect } from 'react';
import api from 'lib/api2';
//import { useNavigate } from "react-router-dom";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

const Elenco=React.memo(({queryString,apiRef,setSelectedRows})=>{
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    //const navigate = useNavigate();
    
    const columns=[
		{ field: 'matricola', headerName: 'Matricola', width: 100, sortable:false, renderCell: r=>r.value.replace(" ","▪") },
		{ field: 'descrizioneTipoStrumento', headerName: 'Strumento', flex: 1, sortable:false },
		{ field: 'descrizioneStruttura', headerName: 'Struttura', flex: 1, sortable:false },
		{ field: 'descrizioneMarca', headerName: 'Marca', flex: 1, sortable:false },
		{ field: 'modello', headerName: 'Modello', flex: 1, sortable:false },
		{ field: 'descrizioneColore', headerName: 'Colore', flex: 1, sortable:false },
    { field: 'annoProduzione', headerName: 'Produzione', flex: 1, sortable:false },
		{
			field: 'valoreCommerciale',
			headerName: 'Valore commerciale',
			flex: 1,
      sortable:false,
			type:'number',
			valueFormatter: params => {
			  return new Intl.NumberFormat('it-IT', {
				style: 'currency',
				currency: 'EUR'
			  }).format(params.value);
			}
		},
    { field: 'descrizioneStato', headerName: 'Stato', flex: 1, sortable:false },
		//{ field: 'dataAggiornamentoStato', headerName: 'Agg. stato', flex: 1, sortable:false, valueFormatter: params => moment(params.value).format('DD/MM/YYYY') },
  	]

    useEffect(()=>{
      setLoading(true);
      api.get("/strumenti?"+queryString).then(data=>{
        setRows(data);
        setLoading(false);
      });
    }, [queryString])

    return	<DataGridPro
        slots={{ toolbar: GridToolbar }} 
        loading={loading}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        hideFooterPagination
        sortingMode="server"
        filterMode="server"
        rowsLoadingMode="server"
        autoHeight={false}
        getRowId={r=>r.matricola}
        getCellClassName={r=>"noedit"}
        showCellRightBorder={false}
        showColumnRightBorder={true}
        checkboxSelection={true}
        onRowClick={ r=>window.open( "/strumenti/"+r.id ) }
        onSelectionModelChange={selected => setSelectedRows(selected)}
        throttleRowsMs={500}
    />
});

export default Elenco;