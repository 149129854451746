import React, { useEffect } from 'react';
import SimpleDataGrid from '../components/SimpleDataGrid';

export default function ContrattiTipi({setTitle,setContextCommands}) {

	const columns=[
    { field: 'codice', headerName: 'Codice', width: 90, editable:true },
    { field: 'descrizione', headerName: 'Descrizione', editable:true, flex:true },
    { field: 'titolo', headerName: 'Titolo', width: 300, editable:true },
  ]

	useEffect(() => {
	    setTitle('Configurazione - Contratti');
  }, [setTitle]);

	return <SimpleDataGrid
      idField="codice"
      getUrl="/contratti/tipi?sort=descrizione"
      postUrl="/contratti/tipi"
      columns={columns}
      setContextCommands={setContextCommands}
    />
}
