import Checkbox from '@mui/material/Checkbox';

export default function cellCheckbox01() {
  const render=params=>{
    var { id, value, api, field }=params;
    return (
      <Checkbox checked={(value==="0")||(!value) ? false : true} onChange={e=>api.setEditCellValue({ id, field, value: value==="0"?"1":"0" })} />
    );
  }

  return{
    renderCell:render,
    renderEditCell:render
  }
}

